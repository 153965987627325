<script setup lang="ts">
import { computed } from "vue";

const emit = defineEmits(['click'])

const props = defineProps({
    dark: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    }
})

const classes = computed(() => {
    if (props.dark) {
        if (!props.disabled) {
            return 'text-stone-100 bg-stone-700 active:bg-stone-600 text-white cursor-pointer'
        } else {
            return 'text-stone-100 bg-stone-300 text-stone-500'
        }
    } else {
        if (!props.disabled) {
            return 'text-stone-900 bg-stone-200 active:bg-stone-300 cursor-pointer'
        } else {
            return 'text-stone-100 bg-stone-300 text-stone-500'
        }
    }
});

const click = function (e: Event) {
    if (!props.disabled) {
        emit("click", e)
    }
}

</script>
<template>
    <div class="rounded-full h-10 w-10 flex justify-center items-center text-xl"
         :class="classes"
         @click="click">
        <div >
            <slot></slot>
        </div>
    </div>
</template>